<script>
	import { tengan, dichi } from "./yi";
	import { createEventDispatcher } from "svelte";

	export let value = "";
	const dispatch = createEventDispatcher();
	let tenId, diId;
	let myTenText, myDiText;
	reset();

	function onTenSelected(event) {
		tenId = parseInt(event.target.dataset.id);
		myTenText = tengan[tenId];
		value = myTenText + myDiText;
		submitIfComplete();
	}

	function onDiSelected(event) {
		diId = parseInt(event.target.dataset.id);
		myDiText = dichi[diId];
		value = myTenText + myDiText;
		submitIfComplete();
	}

	function submitIfComplete() {
		if (tenId != -1 && diId != -1 && tenId % 2 == diId % 2) {
			dispatch("submit", { tenId, diId });
		}
	}

	export function reset() {
		tenId = -1;
		diId = -1;
		myTenText = "";
		myDiText = "";
	}
</script>

<div class="container">
	<div class="label">天干</div>
	<div class="subcontainer">
		{#each tengan as ten, idx}
			{#if idx % 2 == 0}
				<button class="yan" on:click={onTenSelected} data-id={idx}
					>{ten}</button
				>
			{/if}
		{/each}
	</div>
	<div class="subcontainer">
		{#each tengan as ten, idx}
			{#if idx % 2 == 1}
				<button class="yin" on:click={onTenSelected} data-id={idx}
					>{ten}</button
				>
			{/if}
		{/each}
	</div>
	<div class="label">地支</div>
	<div id="dichi" class="subcontainer">
		{#each dichi as di, idx}
			<button
				class={idx % 2 ? "yin" : "yan"}
				on:click={onDiSelected}
				data-id={idx}>{di}</button
			>
		{/each}
	</div>
</div>

<style>
	button {
		width: 70px;
	}
	#dichi button {
		width: 80px;
	}
	button:focus {
		background-color: #ff3e00;
	}

	.subcontainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.label {
		padding-bottom: 10px;
		color: #ff3e00;
	}

	.yin {
		background-color: grey;
		color: white;
	}
</style>
