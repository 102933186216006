export const tengan = "甲乙丙丁戊己庚辛壬癸".split("");
export const dichi = "子丑寅卯辰巳午未申酉戌亥".split("");
export const eightgrams = ["000", "111", "011", "101", "001", "110", "010", "100"];
export const hexagram = {
    // 1-8
    "111111": {id: 1, name: "乾為天", symbol: "䷀"},
    "000000": {id: 2, name: "坤為地", symbol: "䷁"},
    "010001": {id: 3, name: "水雷屯", symbol: "䷂"},
    "100010": {id: 4, name: "山水蒙", symbol: "䷃"},
    "010111": {id: 5, name: "水天需", symbol: "䷄"},
    "111010": {id: 6, name: "天水訟", symbol: "䷅"},
    "000010": {id: 7, name: "地水師", symbol: "䷆"},
    "010000": {id: 8, name: "水地比", symbol: "䷇"},
    // 9-16
    "110111": {id: 9, name: "風天小畜", symbol: "䷈"},
    "111011": {id: 10, name: "天澤履", symbol: "䷉"},
    "000111": {id: 11, name: "地天泰", symbol: "䷊"},
    "111000": {id: 12, name: "天地否", symbol: "䷋"},
    "111101": {id: 13, name: "天火同人", symbol: "䷌"}, 
    "101111": {id: 14, name: "火天大有", symbol: "䷍"},
    "000100": {id: 15, name: "地山謙", symbol: "䷎"},
    "001000": {id: 16, name: "雷地豫", symbol: "䷏"},
    // 17-24
    "011001": {id: 17, name: "澤雷隨", symbol: "䷐"},
    "100110": {id: 18, name: "山風蠱", symbol: "䷑"},
    "000011": {id: 19, name: "地澤臨", symbol: "䷒"},
    "110000": {id: 20, name: "風地觀", symbol: "䷓"},
    "101001": {id: 21, name: "火雷噬嗑", symbol: "䷔"},
    "100101": {id: 22, name: "山火賁", symbol: "䷕"},
    "100000": {id: 23, name: "山地剝", symbol: "䷖"},
    "000001": {id: 24, name: "地雷復", symbol: "䷗"},
    // 25-32
    "111001": {id: 25, name: "天雷無妄", symbol: "䷘"},
    "100111": {id: 26, name: "山天大畜", symbol: "䷙"},
    "100001": {id: 27, name: "山雷頤", symbol: "䷚"},
    "011110": {id: 28, name: "澤風大過", symbol: "䷛"},
    "010010": {id: 29, name: "坎為水", symbol: "䷜"},
    "101101": {id: 30, name: "離為火", symbol: "䷝"}, 
    "011100": {id: 31, name: "澤山咸", symbol: "䷞"},
    "001110": {id: 32, name: "雷風恆", symbol: "䷟"},
    // 33-40
    "111100": {id: 33, name: "天山遯", symbol: "䷠"},
    "001111": {id: 34, name: "雷天大壯", symbol: "䷡"},
    "101000": {id: 35, name: "火地晉", symbol: "䷢"},
    "000101": {id: 36, name: "地火明夷", symbol: "䷣"},
    "110101": {id: 37, name: "風火家人", symbol: "䷤"},
    "101011": {id: 38, name: "火澤睽", symbol: "䷥"},
    "010100": {id: 39, name: "水山蹇", symbol: "䷦"},
    "001010": {id: 40, name: "雷水解", symbol: "䷧"},
    // 41-48
    "100011": {id: 41, name: "山澤損", symbol: "䷨"},
    "110001": {id: 42, name: "風雷益", symbol: "䷩"},
    "011111": {id: 43, name: "澤天夬", symbol: "䷪"},
    "111110": {id: 44, name: "天風姤", symbol: "䷫"},
    "011000": {id: 45, name: "澤地萃", symbol: "䷬"},
    "000110": {id: 46, name: "地風升", symbol: "䷭"},
    "011010": {id: 47, name: "澤水困", symbol: "䷮"},
    "010110": {id: 48, name: "水風井", symbol: "䷯"},
    // 49-56
    "011101": {id: 49, name: "澤火革", symbol: "䷰"},
    "101110": {id: 50, name: "火風鼎", symbol: "䷱"},
    "001001": {id: 51, name: "震為雷", symbol: "䷲"},
    "100100": {id: 52, name: "艮為山", symbol: "䷳"},
    "110100": {id: 53, name: "風山漸", symbol: "䷴"},
    "001011": {id: 54, name: "雷澤歸妹", symbol: "䷵"},
    "001101": {id: 55, name: "雷火豐", symbol: "䷶"},
    "101100": {id: 56, name: "火山旅", symbol: "䷷"},
    // 57-64
    "110110": {id: 57, name: "巽為風", symbol: "䷸"},
    "011011": {id: 58, name: "兌為澤", symbol: "䷹"},
    "110010": {id: 59, name: "風水渙", symbol: "䷺"},
    "010011": {id: 60, name: "水澤節", symbol: "䷻"}, 
    "110011": {id: 61, name: "風澤中孚", symbol: "䷼"},
    "001100": {id: 62, name: "雷山小過", symbol: "䷽"},
    "010101": {id: 63, name: "水火既濟", symbol: "䷾"}, 
    "101010": {id: 64, name: "火水未濟", symbol: "䷿"},
};
