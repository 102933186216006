<script>
	import { tengan, dichi, hexagram } from "./yi";
	import YearChooser from "./YearChooser.svelte";
	import Hexagram from "./Hexagram.svelte";
	import LunarCalendar from "lunar-calendar-zh";
	
	let birthGanZhiYear, year, age;
	let birthYearValue, birthMonthValue, birthDayValue;
	let yearChooser;
	let queryVisible = false;
	let placeholder = "選擇天干地支";
	$: birthView = birthGanZhiYear ? birthGanZhiYear : placeholder;
	$: yearView = year ? year : placeholder;
	$: ageView = age;

	let yearToAge = {};
	let yearQueries = [];

	function populateYearToAge(tenId, diId) {
		clearRecords();
		let i = tenId,
			j = diId;
		for (let age = 1; age <= 120; ++age) {
			const key = tengan[i] + dichi[j];
			if (key in yearToAge) {
				yearToAge[key].push(age);
			} else {
				yearToAge[key] = [age];
			}
			i = (i + 1) % 10;
			j = (j + 1) % 12;
		}
	}

	function onYearQuerySubmit(event) {
		const key =
			tengan[parseInt(event.detail.tenId)] +
			dichi[parseInt(event.detail.diId)];
		if (key in yearToAge) {
			yearQueries = [...yearQueries, { year: key, ages: yearToAge[key] }];
			yearChooser.reset();
		}
	}

	function onBirthChange(event) {
		const element = event.target;
		const maxLength = parseInt(element.attributes["maxlength"].value);
		const value = element.value;
		if (parseInt(value) == 0) {
			return;
		}
		if (value.length == maxLength) {
			const next = element.nextElementSibling;
			if (next != undefined && next.tagName.toLowerCase() == "input") {
				next.focus();
			} else {
				element.blur();
			}
		}
	}

	function clearInput(event) {
		event.target.value = "";
	}

	function calcMonthOffset(year, month) {
		const leapMonth = LunarCalendar.solarToLunar(year, 5,16).lunarLeapMonth;
		return leapMonth == 0 ? 0 : (month > leapMonth) ? 1 : 0;
	}

	function fillinBirthValues() {
		const monthInput = document.getElementById('birthMonth');
		const dayInput = document.getElementById('birthDay');
		if (monthInput.value != undefined && monthInput.value != "" && parseInt(monthInput.value) != 0) {
			birthMonthValue = parseInt(monthInput.value);
		}
		if (dayInput.value != undefined && dayInput.value != "" && parseInt(dayInput.value) != 0) {
			birthDayValue = parseInt(dayInput.value);
		}
	}

	function onBirthSubmit() {
		const yearInput = document.getElementById('birthYear');

		if (parseInt(yearInput.value) == 0) {
			return;
		}

		const monthInput = document.getElementById('birthMonth');
		const dayInput = document.getElementById('birthDay');

		const birthYear = parseInt(yearInput.value) + 1911;
		const birthMonth = parseInt(monthInput.value || "7");
		const birthDay = parseInt(dayInput.value || "1");
		
		const offset = calcMonthOffset(birthYear, birthMonth);
		const solarData = LunarCalendar.lunarToSolar(birthYear, birthMonth + offset, birthDay);
		const lunarData = LunarCalendar.solarToLunar(solarData.year, solarData.month, solarData.day);
		birthYearValue = solarData.year;
		setProfile(lunarData, birthYear);
		queryVisible = true;

		fillinBirthValues();
	}

	function setProfile(lunarData, inputYear) {
		birthGanZhiYear = lunarData.GanZhiYear;
		const ageOffset = calcAgeOffset(inputYear, birthGanZhiYear);
		age = (new Date().getFullYear()) - inputYear + 1 + ageOffset;
		populateYearToAge(tengan.indexOf(birthGanZhiYear[0]), dichi.indexOf(birthGanZhiYear[1]));
	}

	function calcAgeOffset(inputYear, birthGanZhiYear) {
		const inputGanZhiYear = LunarCalendar.solarToLunar(inputYear, 5, 16).GanZhiYear;
		if (inputGanZhiYear == birthGanZhiYear) {
			return 0;
		}
		return tengan.indexOf(inputGanZhiYear[0]) - tengan.indexOf(birthGanZhiYear[0]);
	}

	function clearRecords() {
		yearToAge = {}
		yearQueries = [];
		year = "";
	}

	function resetProfile() {
		clearRecords();
		birthGanZhiYear = "";
		age = "";
		queryVisible = false;
		document.getElementById('birthYear').value = "";
		document.getElementById('birthMonth').value = "";
		document.getElementById('birthDay').value = "";
		birthYearValue = undefined;
		birthMonthValue = undefined;
		birthDayValue = undefined;
	}
</script>

<main>

	<div class="birhtInput">農曆民國 <input pattern="[0-9]*" type="text" inputmode="numeric" size=3 maxlength=3 placeholder="073"
			id="birthYear" class="bdayInput" on:click={resetProfile} on:blur={onBirthSubmit} on:input={onBirthChange} /> 年
			<input pattern="[0-9]*" type="text" inputmode="numeric" size=2 maxlength=2 placeholder="04"
			id="birthMonth" class="bdayInput" on:click={clearInput} on:blur={onBirthSubmit} on:input={onBirthChange} /> 月
			<input pattern="[0-9]*" type="text" inputmode="numeric" size=2 maxlength=2 placeholder="16"
			id="birthDay" class="bdayInput" on:click={clearInput} on:blur={onBirthSubmit} on:input={onBirthChange} /> 日
	</div>
	<h1>
		<span class={birthGanZhiYear ? "show normal" : "hide"}><span class="highlight">{birthView}</span>年出生，</span>
		<span class={age ? "show normal" : "hide"}>現年 <span class="highlight">{ageView}</span> 歲</span>
	</h1>
	<Hexagram yearValue={birthYearValue} monthValue={birthMonthValue} dayValue={birthDayValue} />
	{#if queryVisible}
		<h1>
			查詢年: <span class={year ? "normal" : "fade"}>{yearView}</span>
		</h1>
		{#each yearQueries as query}
			<div class="queryResult">
				{query.year}年：{query.ages.map((age) => age + "歲").join("、")}
			</div>
		{/each}
		<div id="yearSelect">
			<YearChooser
				bind:this={yearChooser}
				bind:value={year}
				on:submit={onYearQuerySubmit}
			/>
		</div>
	{/if}
</main>

<style>
	main {
		text-align: left;
		max-width: 370px;
		margin: 0 auto;
		font-size: 1.5em;
	}

	h1 {
		color: #ff3e00;
		font-size: 1.2em;
		margin: 10px 0;
	}

	.birhtInput {
		color: black;
		font-weight: bold;
		background-color: lightyellow;
		padding: 2px;
		border: grey solid;
		border-radius: 10px;
	}

	.birhtInput input {
		padding: 2px;
		margin: 0;
		font-size: 1.1em;
	}

	.birhtInput input::placeholder {
		color: #dfdfdf;
		opacity: 1; /* Firefox */
	}

	#birthYear {
		width: 2em;
	}

	#birthMonth, #birthDay {
		width: 1.5em;
	}

	.queryResult {
		margin: 10px 0;
	}

	.hide {
		display: none;
	}
	.show {
		display: inline-block;
	}

	.highlight {
		color: #ff3e00;
		font-weight: bold;
	}
	.normal {
		color: black;
		font-weight: normal;
	}
	.fade {
		color: gray;
		font-weight: 100;
	}

</style>
