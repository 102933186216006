<script>
    import { eightgrams, hexagram } from "./yi";

    export let yearValue; // solar year
    export let monthValue;
    export let dayValue;
    
    function getGramName(month, day) {
        if (!month || !day) {
            return undefined;
        }
        const dayGram = eightgrams[day % 8];
        const monthGram = eightgrams[month % 8];
        return hexagram[dayGram+monthGram];
    }

    $: gram = getGramName(monthValue, dayValue);
</script>
{#if gram}
    <div class="gramInfo">
        <span class="highlight">命卦: </span>{gram.name}<span class="gram">{gram.symbol}</span>
        <span><a href="https://ytliu0.github.io/ChineseCalendar/index_chinese.html?y={yearValue}" target="_blank">
            {yearValue}年曆</a></span>
    </div>
{/if}
<style>
    .gramInfo {
        height: 2em;
        font-size: 1.2em;
    }
    .gram {
        font-size: 2em;
        vertical-align: middle;
    }
	.highlight {
		color: #ff3e00;
		font-weight: bold;
	}
    a {
        margin-left: 5px;
        padding: 5px;
        font-size: 0.8em;
        background-color: #993e00;
        color: white;
        border-radius: 5px;
    }
</style>